/*
These functions make sure WordPress
and Foundation play nice together.
*/

function negativeMarginCart() {

	if(jQuery(window).width() >= 1024) {
		jQuery('.go-up-by-calculation').css('margin-top', '-'+jQuery('.use-this-for-calculation').height()+'px');
		console.log('sss');
	} else {
		jQuery('.go-up-by-calculation').css('margin-top', '0px');
	}
	
}

var stickyshower = false;

//jQuery(document).ready(function() {
jQuery(document).ready(function($){
	setTimeout(function(){
		stickyshower = true;
		$('.balance-bike-top-sticky').fadeIn(250);
	}, 10000);

	//shshshs
	//coupon-expander

		$(document).on('click', ".coupon-expander a", function (e) {
	    	e.preventDefault();
	    	$('.coupon-ccccusttt').toggleClass('showwing');
	    	negativeMarginCart();
	    	
	    });	

	    /*$('input[name="terms-field"]').prev().trigger('click');*/


	    //woocommerce-terms-and-conditions-link woocommerce-terms-and-conditions-link--closed

	    $('body').on('click', ".woocommerce-terms-and-conditions-link", function (e) {
	    	e.preventDefault();
	    	console.log('ezzzz');
	    	window.open($(this).attr('href'), '_blank'); 
	    	
	    });	

	    //product-quantity

	    $(document).on('click', ".icon.icon-edit", function (e) {
	    	e.preventDefault();
	    	$(this).next().toggleClass('edittting');
	    	$(this).toggleClass('editing');

	    	if(!$(this).next().hasClass('edittting')) {
	    		$('button[name="update_cart"]').trigger('click');
	    	}
	    	
	    	
	    });	

		$(document).on('click', ".size-choos-a-toy-ccc", function (e) {
	    	e.preventDefault();
	    	jQuery('.size-choos-a-toy-ccc').removeClass('actifff');
	    	jQuery(this).addClass('actifff');

	    	if($(this).attr('data-id') == 1) {
	    		jQuery('.image-variable-item-rounded-edge-gray, .image-variable-item-sloping-edge-gray, .image-variable-item-with-pull-rope-gray, .image-variable-item-rounded-edge-gray-de, .image-variable-item-sloping-edge-gray-de, .image-variable-item-with-pull-rope-gray-de').show();
	    		jQuery('.image-variable-item-rounded-edge-gray, .image-variable-item-sloping-edge-gray, .image-variable-item-with-pull-rope-gray, image-variable-item-rounded-edge-gray-de, .image-variable-item-sloping-edge-gray-de, .image-variable-item-with-pull-rope-gray-de').css('display', 'flex');
	    		jQuery('.image-variable-item-rounded-edge, .image-variable-item-sloping-edge, .image-variable-item-with-pull-rope, .image-variable-item-rounded-edge-de, .image-variable-item-sloping-edge-de, .image-variable-item-with-pull-rope-de').hide();
	    		jQuery('.image-variable-item-with-pull-rope-gray, .image-variable-item-with-pull-rope-gray-de').trigger('click');
	    	} else {
	    		jQuery('.image-variable-item-rounded-edge, .image-variable-item-sloping-edge, .image-variable-item-with-pull-rope, .image-variable-item-rounded-edge-de, .image-variable-item-sloping-edge-de, .image-variable-item-with-pull-rope-de').show();
	    		jQuery('.image-variable-item-rounded-edge-gray, .image-variable-item-sloping-edge-gray, .image-variable-item-with-pull-rope-gray, .image-variable-item-rounded-edge-gray-de, .image-variable-item-sloping-edge-gray-de, .image-variable-item-with-pull-rope-gray-de').hide();
	    		jQuery('.image-variable-item-with-pull-rope, .image-variable-item-with-pull-rope-de').trigger('click');
	    	}
	    });


	    $(document).on('click', ".type-choos-a-toy-ccc", function (e) {
	    	e.preventDefault();
	    	jQuery('.type-choos-a-toy-ccc').removeClass('actifff');
	    	jQuery(this).addClass('actifff');

	    	if($(this).attr('data-id') == 1) {
	    		jQuery('.image-variable-item-oak-large, .image-variable-item-birch-large, .image-variable-item-walnut-large, .image-variable-item-gray-large').show();
	    		jQuery('.image-variable-item-oak-large, .image-variable-item-birch-large, .image-variable-item-walnut-large, .image-variable-item-gray-large').css('display', 'flex');
	    		jQuery('.image-variable-item-oak, .image-variable-item-birch, .image-variable-item-walnut, .image-variable-item-gray').hide();
	    		jQuery('.image-variable-item-oak-large').trigger('click');
	    	} else {
	    		jQuery('.image-variable-item-oak, .image-variable-item-birch, .image-variable-item-walnut, .image-variable-item-gray').show();
	    		jQuery('.image-variable-item-oak-large, .image-variable-item-birch-large, .image-variable-item-walnut-large, .image-variable-item-gray-large').hide();
	    		jQuery('.image-variable-item-oak').trigger('click');
	    	}
	    });

	//sggsggs

	jQuery('.hamburger').click(function(e) { 
	//console.log('e');

		e.preventDefault();
			jQuery(this).toggleClass('is-active');
		if(jQuery(this).hasClass('is-active')){
			jQuery('.mob-nav').fadeIn('slow');
		}
		else {
			jQuery('.mob-nav').fadeOut('slow');
		}
	});

	//jQuery('.show-more-content').click(function(e) { 
	jQuery(document).on('click', ".show-more-content", function (e) {
	//console.log('e');

		e.preventDefault();

		if(!jQuery(this).hasClass('opened')){
			jQuery(this).closest('.product.type-product').find('.full-text').show();
			jQuery(this).addClass('opened');
		} else {
			jQuery(this).closest('.product.type-product').find('.full-text').hide();
			jQuery(this).removeClass('opened');
		}

	});


	var paramsszo = new window.URLSearchParams(window.location.search);

	jQuery('.collaps_buttn').click(function(e) { 
        e.preventDefault(); 

        if(!jQuery(this).hasClass('opened')){

          jQuery('.collapse').hide();
          jQuery(this).parent().next().show();
          jQuery('.collaps_buttn').removeClass('opened');
          jQuery(this).addClass('opened');
          if(jQuery(window).width()<890){


            if(paramsszo.get('c') && paramsszo.get('i')) {
              if(paramsszo.get('i') == '0') {
                var toprkrkss = 270;
                //console.log(toprkrkss);
              }
              else {
                var toprkrkss = 230;
              }
            } else {
              var toprkrkss = 230;
            }


            jQuery('html, body').animate({scrollTop: jQuery(this).offset().top-toprkrkss}, 'slow');

          }
          else {

            if(paramsszo.get('c') && paramsszo.get('i')) {
              if(paramsszo.get('i') == '0') {
                var toprkrkss = 140;
                //console.log(toprkrkss);
              }
              else {
                var toprkrkss = 100;
              }
            } else {
              var toprkrkss = 100;
            }

            jQuery('html, body').animate({scrollTop: jQuery(this).offset().top-toprkrkss}, 'slow');
          }
        } else {
          jQuery(this).removeClass('opened');
           jQuery('.collapse').hide();
           jQuery('html, body').animate({scrollTop: 0}, 'slow');
        }
      });


	jQuery('.support-block-menu ul li').click(function(e) { 
        e.preventDefault(); 
        //console.log('h5.wichsec-'+$(this).attr('data-id'));
        //console.log($('h5.wichsec-'+$(this).attr('data-id')));

         if(jQuery(window).width()<890){
          jQuery('html, body').animate({scrollTop: jQuery('h5.wichsec-'+jQuery(this).attr('data-id')).offset().top-230}, 'slow');
        }
        else {
          jQuery('html, body').animate({scrollTop: jQuery('h5.wichsec-'+jQuery(this).attr('data-id')).offset().top-100}, 'slow');
        }
      });


	//jQuery('.collapsable-block-title').click(function(e) {  
	jQuery(document).on('click', ".collapsable-block-title", function (e) {

	    if(!jQuery(this).hasClass('opened')){
	      jQuery('.collapsable-block-content').hide();
	      jQuery(this).find('.collapsable-block-content').show();
	      jQuery('.collapsable-block-title').removeClass('opened');
	      jQuery(this).addClass('opened');
	    } else {
	      jQuery('.collapsable-block-content').hide();
	      jQuery('.collapsable-block-title').removeClass('opened');

	    }
	  });

	console.log('eeee');

	jQuery('<div class="quantity-nav"><div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div></div>').insertAfter('.quantity input');
    jQuery('.quantity').each(function() {
      var spinner = jQuery(this),
        input = spinner.find('input[type="number"]'),
        btnUp = spinner.find('.quantity-up'),
        btnDown = spinner.find('.quantity-down'),
        min = input.attr('min'),
        max = input.attr('max');
        max = 5;
        /*if (max == '') {
        	console.log(max);
    		max = 10;
    	}*/

      btnUp.click(function() {
        var oldValue = parseFloat(input.val());
        if (oldValue >= max) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue + 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });

      btnDown.click(function() {
        var oldValue = parseFloat(input.val());
        if (oldValue <= min) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue - 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });

    });

    console.log(jQuery('.use-this-for-calculation').height());

    negativeMarginCart();
    
    jQuery('.checkout-content-fade').css('opacity', 1);

	if($('body').hasClass('page-template-page-balance-bike')){
		$('#main-nav #menu-item-33').addClass('houvered');
	}

	/*$('.add-to-cart-btn').click(function(e) {
	    e.preventDefault();
	    var paramArr = new Array();
    	paramArr.push( {name:'add_to_cart', value:true} );
	    paramArr.push( {name:'action', value:'add_to_cart'} );
	    paramArr.push( {name:'product_id', value:parseInt($(this).attr('data-product-sku'))} );
	    console.log(parseInt($(this).attr('data-product-sku')));

	    $('.addpopuapapa').css('background-image', 'url("'+$(this).attr("data-thumb")+'")');
	    $('.ac-popupp-changable-text').text($(this).attr("data-title"));

	    $('.add-to-cart-ppopupp').show();
	    $('.add-to-cart-ppopupp').css('right', '100px');

	    setTimeout(function(){
    		$('.add-to-cart-ppopupp').css('right', '-400px');
    		$('.add-to-cart-ppopupp').fadeOut('slow');
    	}, 5000);


	    jQuery.ajax({
	        url : ajaxurl,
	        type : 'post',
	        data : $.param(paramArr),
	        success : function( response ) {        
	            console.log(response);
	            response = $.parseJSON(response);
	            if(response.quantity > 0) {
	            	$('.black-grozs').hide();
	            	$('.green-grozs').show();
	            	$('.result-count').show();
	            	$('.cart-count-span').text(response.quantity);
	            }
	        }
	    });
 	});*/

	$('.cook-approve').click(function(e) {
	    e.preventDefault();
	    var paramArr = new Array();
	    paramArr.push( {name:'cookapprove', value:true} );
	    paramArr.push( {name:'action', value:'cookapprove'} );
	    $('.cook-div').fadeOut('slow');
	    jQuery.ajax({
        	url : ajaxurl,
	        type : 'post',
	        data : $.param(paramArr),
	        success : function( response ) {        
	            
	        }
	    });
 	});

 	$('.double-item').slick({
 		dots: false,
     	arrows: false,
     	vertical: true
  	});

	if($('body').hasClass('page-template-page-addons') || $('body').hasClass('page-template-page-bundles')){
		var adaptivechecker = false;
	} else {
		var adaptivechecker = true;
	}

	console.log('teesu2');

 	$('.main-slider').slick({
 	 	fade: true,
 	 	lazyLoad: 'ondemand',
     	dots: false,
     	arrows: false,
     	adaptiveHeight: adaptivechecker
  	});

  	if($('body').hasClass('page-template-page-checkout')){
  		$('.ram_select2').selectWoo();
  	}


  	$('.page-template-page-balance-bike .thumbnail-content').on('afterChange', function(slick, currentSlide){
    //console.log(currentSlide.currentSlide);

	    $(slick.currentTarget).parent().find('.thumbnail-content-2').slick('slickGoTo', currentSlide.currentSlide);
	});


	$('.round-thumberz-c').click(function(e) {  
	  //console.log($(this).parent().parent().parent().parent().parent());
	   $(this).parent().parent().parent().parent().parent().find('.thumbnail-content').slick('slickGoTo', $(this).attr('data-id'));
	 });

	$('.thumbnail-block').click(function(e) {  

      
       $(this).find('.thumbnail-content').slick('slickNext');
      
    });

  	$('.page-template-page-balance-bike .thumbnail-content-2').slick({
       lazyLoad: 'ondemand',
       slidesToShow: 5,
        slidesToScroll: 5,
        arrows: false,
        vertical: false,
		responsive: [
		                {
		                  breakpoint: 360,
		                  settings: {
							slidesToShow: 4,
        					slidesToScroll: 4,
		                    
		                  }
		                }
		               

		              ]
         //asNavFor: '.page-template-page-toys .thumbnail-content'
    });

    $('.page-template-page-balance-bike .thumbnail-content').slick({
       lazyLoad: 'ondemand',
       autoplay: true,
 	   autoplaySpeed: 3000,
       fade: true,
         dots: false,
         arrows: true,
            prevArrow:"<div class='slick-prev pull-left'></div>",
            nextArrow:"<div class='slick-next pull-right'></div>",

         vertical: false,
         responsive: [
                            {
                              breakpoint: 890,
                              settings: {
                                adaptiveHeight: true
                                
                              }
                            }
                           

                          ]
         //asNavFor: '.page-template-page-toys .thumbnail-content-2'
      });

	 // On before slide change
	$('.main-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
  		//console.log(event);
	});
	$('.main-slider').on('afterChange', function(slick, currentSlide){
  		/*console.log(currentSlide.currentSlide);*/
  		if($(this).find('div[data-id="'+currentSlide.currentSlide+'"]').find('iframe').length > 0) {
	  		var actualiframe = $(this).find('div[data-id="'+currentSlide.currentSlide+'"]').find('iframe');

	  		if(!$(actualiframe).attr('src')){
	  			$(actualiframe).attr('src', $(actualiframe).attr('data-src'));
	  		}
	  	} else {
	  		$(this).find('iframe').attr('src', '');
	  	}
	  	//console.log($(this).closest('.cell.large-5.reorder-2'));
	  	if(!$('body').hasClass('page-template-page-accessories') && !$('body').hasClass('page-template-page-toys') && !$('body').hasClass('page-template-page-shop-home')){
    		$(this).closest('.cell.large-5.reorder-2').find('.little-slider').slick('slickGoTo',currentSlide.currentSlide);
    	} else {
    		$(this).closest('.cell.image-div-wrap-block.reorder-2').find('.little-slider').slick('slickGoTo',currentSlide.currentSlide);
    	}
	});

	if($('body').hasClass('page-template-page-addons')){
		$('.little-slider').slick({
			slidesToShow: 5,
	        slidesToScroll: 5,
	        arrows: false,
	     	vertical: false, 
	     	swipe: false,
	     	


	  	});
	} else {

	 	$('.little-slider').slick({
	 		lazyLoad: 'ondemand',
			slidesToShow: 5,
	        slidesToScroll: 5,
	        arrows: false,
	     	vertical: false, 
	     	swipe: false,
	     	responsive: [
	        {
	          breakpoint: 868,
	          settings: {
	            slidesToShow: 4,
				slidesToScroll: 4,
	            
	          }
	        },
	        {
	          breakpoint: 443,
	          settings: {
	            slidesToShow: 3,
				slidesToScroll: 3,
	            
	          }
	       }

	      ]


	  	});
	 }

  	setTimeout(function(){
  		$('.image-div-wrap-block').addClass('loaded');
		
	}, 500);

  	/*$('.little-slider').on('afterChange', function(slick, currentSlide){
  		
	  	//console.log($(this).closest('.cell.large-5.reorder-2'));
    	$(this).closest('.cell.large-5.reorder-2').find('.main-slider').slick('slickGoTo',currentSlide.currentSlide);
	});*/

	$(document).on('click', ".go-to-big-slider-slide", function (e) {
  	//$('.go-to-big-slider-slide').click(function(e) { 
    	e.preventDefault(); 
    	console.log($(this).attr('data-id'));
    	console.log($(this).closest('.cell.large-5.reorder-2'));
    	if(!$('body').hasClass('page-template-page-accessories') && !$('body').hasClass('page-template-page-toys') && !$('body').hasClass('page-template-page-shop-home')){
    		$(this).closest('.cell.image-div-wrap-block.reorder-2').find('.main-slider').slick('slickGoTo',$(this).attr('data-id'));
    		//$(this).closest('.cell.large-5.reorder-2').find('.main-slider').slick('slickGoTo',$(this).attr('data-id'));
    	} else {
    		$(this).closest('.cell.image-div-wrap-block.reorder-2').find('.main-slider').slick('slickGoTo',$(this).attr('data-id'));
    	}
    });

 	$('.slidetrigg').click(function(e) { 
    	e.preventDefault(); 
        $('.slidetrigg').removeClass('active')
        $(".slidetrigg[data-id='"+$(this).attr('data-id')+"']").addClass('active');
        $('.double-item').slick('slickGoTo',$(this).attr('data-id'));

  	});

  	$('.double-item .item').click(function(e) { 
    	$('.double-item').slick('slickNext');
    });

    //$('.slider-navig-buttons-prev').click(function(e) { 
	$(document).on('click', ".slider-navig-buttons-prev", function (e) {
    	e.preventDefault();
    	
    	$(this).parent().parent().parent().find('.main-slider').slick('slickPrev');
    	$(this).parent().parent().parent().find('.trust-slider').slick('slickPrev');
    });
    //$('.slider-navig-buttons-next').click(function(e) { 
	$(document).on('click', ".slider-navig-buttons-next", function (e) {
    	e.preventDefault();
    	
    	$(this).parent().parent().parent().find('.main-slider').slick('slickNext');
    	$(this).parent().parent().parent().find('.trust-slider').slick('slickNext');
    });

    //$('.main-slider').click(function(e) { 
	$(document).on('click', ".main-slider", function (e) {
    	e.preventDefault();
    	
    	$(this).parent().parent().parent().find('.main-slider').slick('slickNext');
    });

	  // On before slide change
  	$('.double-item').on('afterChange', function(event, slick, currentSlide, nextSlide){

       $('.slidetrigg').removeClass('active')
       $(".slidetrigg[data-id='"+currentSlide+"']").addClass('active');
	       
    });

	$('.double-item').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      
		if(nextSlide == 1){
       		$('.height-text').html('34 CM');
       		$('.heightlinewrapper').css('top', '-5%')
		}
		if(nextSlide == 2){
			$('.height-text').html('40 CM');
			$('.heightlinewrapper').css('top', '-7%')
		}
		if(nextSlide == 3){
			$('.height-text').html('47 CM');
			$('.heightlinewrapper').css('top', '-13%')
		}
		if(nextSlide == 0){
			$('.height-text').html('25 CM');
			$('.heightlinewrapper').css('top', '0%')
		}
    });

	$('.video-player img').click(function(e) {
	    e.preventDefault();
	    console.log($(this).next());
	    var vid = $(this).next(); 
	    vid[0].play();
	    $(this).next().css('display', 'block');
	    $(this).hide();

	});

	$('.video-player video').click(function(e) {
	    e.preventDefault();
	    var vid = $(this); 
	    vid[0].pause();
	    $(this).prev().show();
	    $(this).hide();

	});

	$('.video-player video').on('ended',function(){
      console.log('Video has ended!');
        var vid = $(this); 
      	vid[0].pause();
	    $(this).prev().show();
	    $(this).hide();
    });

	$("#main-nav li.menu-item-has-children").hoverIntent({
		over: addHoverClass,
	    out: removeHoverClass,
	    timeout: 200
	 });

	function addHoverClass() {

		if($(window).width() > 868){
			console.log($(this));
			$(this).addClass('houvered');
		}
	}
	function removeHoverClass() {
		//$("#main-nav li.menu-item-has-children").removeClass('houvered');
		if($(window).width() > 868){
			$(this).removeClass('houvered');
		}
	}

	$('.custom-section-part-addons .switcher-tab').click(function() {
		$('.custom-section-part-addons .switcher-tab').removeClass('active');
		$(this).addClass('active');
  		$('.custom-section-part-addons .margin-back-left-top img').attr('src', $(this).attr('data-image'));
  		$('.custom-section-part-addons .absolute-text-over-addon .textual').html($(this).attr('data-text'));
  		console.log($(this).attr('data-more-link'));
  		$('.custom-section-part-addons .absolute-text-over-addon .btn').attr('href', $(this).attr('data-more-link'));
	});



	if($('body').hasClass('home')) {
		lazyLoadCustom($('body')); 

		$('.trust-slider').slick({
	        dots: true,
	        arrows: false,
	        adaptiveHeight: false
	    });
	}

	if($('body').hasClass('page-template-page-balance-bike')) {
		lazyLoadCustom($('body')); 
	}
	if($('body').hasClass('page-template-page-addons')) {
		console.log('e');
		lazyLoadCustom($('body')); 
	}
	if($('body').hasClass('page-template-page-accessories')) {
		console.log('e');
		lazyLoadCustom($('body')); 
	}
	if($('body').hasClass('page-template-page-bundles')) {
		console.log('e');
		lazyLoadCustom($('body')); 
	}
	if($('body').hasClass('page-template-page-toys')) {
		console.log('e');
		lazyLoadCustom($('body')); 
	}

	if($('body').hasClass('page-template-page-shop-home')) {
		console.log('e');
		lazyLoadCustom($('body')); 
	}
	
	// Remove empty P tags created by WP inside of Accordion and Orbit
	jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});

	jQuery('.subscribe-news').click(function(e) { 
		e.preventDefault();
		var paramArr = new Array();
	    paramArr.push( {name:'mailigensubscribe', value:true} );
	    paramArr.push( {name:'action', value:'mailigensubscribe'} );
	    paramArr.push( {name:'mail_value', value:jQuery('#subscribe-inp').val()} );
	    //$('.cook-div').fadeOut('slow');
	    jQuery.ajax({
        	url : ajaxurl,
	        type : 'post',
	        data : $.param(paramArr),
	        success : function( response ) {     
	        	response = jQuery.parseJSON(response);
	        	console.log(response); 
	        	if(response.success) {
	            	jQuery('.submit-neww').hide();
	            }  else {
	            	console.log('fail');
	            	jQuery('#subscribe-inp').css('border', '1px solid red');
	            	jQuery('#subscribe-inp').css('color', 'red');
	            }
	        }
	    });
	});


	// scrolll mega
	$ = jQuery;

	$(window).scroll(function() {
	  
		var st = $(this).scrollTop();

		if($('body').hasClass('parent-pageid-9') || $('body').hasClass('parent-pageid-80')){
			if(st < 50){
				$('#main-nav #menu-item-33').addClass('houvered');
				$('.balance-bike-top-sticky').fadeOut(150);
			} else {
				$('#main-nav #menu-item-33').removeClass('houvered');
				if(stickyshower) {
					$('.balance-bike-top-sticky').fadeIn(250);
				}
			}
		}
		if($('body').hasClass('page-template-page-support')) {
	        var rt = (/*$(window).width() - */($('.detect-side').offset().left+15 /*+ $('.detect-side').outerWidth()*/));
	        if($(window).width() > 900/* && st < (900-$('.detect-side').height())*/){

	        	$('.support-block-menu').css('position', 'fixed');
	        	$('.support-block-menu').css('left', rt+'px');
	        	$('.support-block-menu').css('bottom', 'auto');


				if(st < 50){
					var op = 150-st;
					$('.support-block-menu').css('top', op+'px');
					$('.support-block-menu').css('bottom', 'auto');
				}
				else {
					$('.support-block-menu').css('top', 100+'px');
				}

	        } else {
				$('.support-block-menu').css('position', 'fixed');
				$('.support-block-menu').css('left', '0px');
				$('.support-block-menu').css('bottom', 'auto');
				$('.support-block-menu').css('top', '59px');
	        }
	    }

		if($('body').hasClass('page-template-page-checkout') || $('body').hasClass('page-template-page-fake-checkout')) {
			// disabled on new page cart sticker
			/*var rt = ($(window).width() - ($('.detect-side').offset().left + $('.detect-side').outerWidth()));
			if($(window).width() > 900 && st < (900-$('.carto-itemz-hc').height())){

				$('.right-ca-div').css('position', 'fixed');
				$('.right-ca-div').css('right', rt+'px');
				$('.right-ca-div').css('bottom', 'auto');
				if(st < 50){
					var op = 150-st;
					$('.right-ca-div').css('top', op+'px');
					$('.right-ca-div').css('bottom', 'auto');
				}
				else {
					$('.right-ca-div').css('top', 100+'px');
				}

			} else {
				$('.right-ca-div').css('position', 'absolute');
				$('.right-ca-div').css('right', '0px');
				$('.right-ca-div').css('bottom', '0px');
				$('.right-ca-div').css('top', 'auto');
			}*/
		}

		if($(window).width() > 900){

		}
		if(st < 213) {
			if($(window).width() > 900){
				$('.subheader').css('top', 93-st+'px');
			}
			else {
				$('.subheader').css('top', 59-st+'px');
			}
		} else {

		}
		if(st < 100) {
			$('.bundle-top').hide();
		}
		else {
			$('.bundle-top').fadeIn('slow');
		}

	    if(st > 100) {
			$('body').addClass('muchscrolled');
			$('.scroll-animation').removeClass('notscrolled');
	       
		} else {
			$('body').removeClass('muchscrolled');
			$('.scroll-animation').addClass('notscrolled');
	    }

		if($(window).width()>890){
			if(st < 100) {
				if($(window).height()<690){
					$('.animating-bike-div').css('top', 300-st+'px');
				} else {
					$('.animating-bike-div').css('top', '300px');
				}
			}
			else {
				if($(window).height()<690){
					$('.animating-bike-div').css('top', '200px');
				} else {
					$('.animating-bike-div').css('top', '300px');
				}
			}
		}
		else {
			if(st < 200) {
				if($(window).height()<690){
					$('.animating-bike-div').css('top', 300-st+'px');
				} else {
					$('.animating-bike-div').css('top', '300px');
				}
			}
			else {
				if($(window).height()<690){
					$('.animating-bike-div').css('top', '100px');
				} else {
					$('.animating-bike-div').css('top', '300px');
				}
			}
		}

		if($(window).width()>890){
	        if(st < 310) {
	            $('.home-block-1 h1').css('margin-top', (50-st)+'px');
	        }
	        else {
	            $('.home-block-1 h1').css('margin-top', '-270px');
	        }
			if(st < 250) {
				$('.info-block').css('margin-top', (30+st)+'px');
			}
			else {
				$('.info-block').css('margin-top', '280px');
			}
	    }
	    else {

	    	var heightdependent;
	    	var heightdependent2;
	    	if($(window).height()<690){
	    		heightdependent = 200;
	    		heightdependent2 = 160;
	    	} else {
	    		heightdependent = 300;
	    		heightdependent2 = 260;
	    	}



	        if(st < heightdependent) {

	        	if($(window).height()<690){
	            	$('.home-block-1 h1').css('margin-top', (40-st)+'px');
	            } else {
	            	$('.home-block-1 h1').css('margin-top', '40px');
	            }
	           
	        }
	        else {
	        	if($(window).height()<690){
	            	$('.home-block-1 h1').css('margin-top', '-'+heightdependent2+'px');
	             } else {	
	             	$('.home-block-1 h1').css('margin-top', '40px');
	             }
	        }
	    }
		if(window.pageYOffset < 0) {
			$('.vidodif').css('opacity', '0');
			$('.vidodif[data-id="bike1_00001.png"]').css('opacity', '1');
		}
		if(window.pageYOffset < 2445 && window.pageYOffset > 0) {
			if(Math.round(window.pageYOffset/55) <= 39) {
				$('.vidodif').css('opacity', '0');
				$('.vidodif[data-id="bike1_'+padZerosToLength(Math.round(window.pageYOffset/55), 5, 0)+'.png"]').css('opacity', '1');
			}
			else {
				$('.vidodif').css('opacity', '0');
				$('.vidodif[data-id="bike1_00039.png"]').css('opacity', '1');
			}
		}
		if(window.pageYOffset >= 2445) {
			if(Math.round((window.pageYOffset-2445)/55) <= 39) {
				$('.vidodif').css('opacity', '0');
				$('.vidodif[data-id="bike2_'+padZerosToLength(Math.round((window.pageYOffset-2445)/55), 5, 0)+'.png"]').css('opacity', '1');
				if(Math.round((window.pageYOffset-2445)/55) == 0) {
					$('.vidodif').css('opacity', '0');
					$('.vidodif[data-id="bike2_00001.png"]').css('opacity', '1');
				}
			}
			else {
				$('.vidodif').css('opacity', '0');
				$('.vidodif[data-id="bike2_00039.png"]').css('opacity', '1');
			}
		}
		if(window.pageYOffset < 1600 && st > 305) {
			$('.show-info-bl-3').hide();
			$('.show-info-bl-2').hide();
			$('.show-info-bl-1').show();
			$('.show-info-bl-3').css('opacity', '0');
			$('.show-info-bl-2').css('opacity', '0');
			$('.show-info-bl-1').css('opacity', '1');
		}
		if(window.pageYOffset > 1600 && window.pageYOffset < 2400 ) {
			$('.show-info-bl-2').show();
			$('.show-info-bl-1').hide();
			$('.show-info-bl-3').hide();
			$('.show-info-bl-3').css('opacity', '0');
			$('.show-info-bl-1').css('opacity', '0');
			$('.show-info-bl-2').css('opacity', '1');
		}
		if(window.pageYOffset > 3200 && window.pageYOffset < 4800 ) {
			$('.show-info-bl-3').show();
			$('.show-info-bl-2').hide();
			$('.show-info-bl-1').hide();
			$('.show-info-bl-3').css('opacity', '1');
			$('.show-info-bl-2').css('opacity', '0');
			$('.show-info-bl-1').css('opacity', '0');
		}
	});

	//window resize 
	$( window ).resize(function() {
		negativeMarginCart();
	});

}); 

// todo - windows resize, functions, needs to reload page when resizing, for new image appending (smaller images) in home page

function padZerosToLength (value, minLength, padChar) {
    var iValLength= value.toString().length;
    return ((new Array((minLength + 1) - iValLength).join(padChar)) + value);
}

function lazyLoadCustom( parent ){
    var images = jQuery(parent).find('img[name="data-src-custom"]');
    jQuery.each( images, function( i, img ){
        var src = jQuery(img).attr('data-src-custom');
        jQuery(img).attr('src', src);
    });

}



function ReloadIframes(){

	if(jQuery('body').hasClass('page-template-page-support')){
	    jQuery('iframe[data-src]').each( function(){
	        //* set the img src from data-src
	        jQuery( this ).attr( 'src', jQuery( this ).attr( 'data-src' ) );
	        }
	    );
	}
}





window.addEventListener("load", function(){
    // ....
     ReloadIframes();
});


var canBeLoaded = true;
jQuery(function($){
		

		if($('body').hasClass('page-template-page-toys')){

			//return true;

			var data = {
				'action': 'loadmore'
			};

			$.ajax({
				url : ajaxurl,
				data:data,
				type:'POST',
				beforeSend: function( xhr ){
					// you can also add your own preloader here
					// you see, the AJAX call is in process, we shouldn't run it again until complete
					canBeLoaded = false; 
				},
				success:function(data){
					if( data ) {

						//console.log(data);
						/*$('#main').find('article:last-of-type').after( data ); // where to insert posts
						canBeLoaded = true; // the ajax is completed, now we can run it again
						misha_loadmore_params.current_page++;*/

						jQuery('.content').append(data);
						
					  	$('.main-slider').not('.slick-initialized').slick({
						  	fade: true,
					 	 	lazyLoad: 'ondemand',
					     	dots: false,
					     	arrows: false,
					     	adaptiveHeight: true
						});

						$('.little-slider').not('.slick-initialized').slick({
							slidesToShow: 5,
							lazyLoad: 'ondemand',
					        slidesToScroll: 5,
					        arrows: false,
					     	vertical: false, 
					     	swipe: false,
					     	responsive: [
					        {
					          breakpoint: 868,
					          settings: {
					            slidesToShow: 4,
								slidesToScroll: 4,
					            
					          }
					        },
					        {
					          breakpoint: 443,
					          settings: {
					            slidesToShow: 3,
								slidesToScroll: 3,
					            
					          }
					       }

					      ]


					  	});
						setTimeout(function(){
				  			$('.image-div-wrap-block').addClass('loaded');
						
						}, 500);

						$('.main-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
					  		//console.log(event);
						});
						$('.main-slider').on('afterChange', function(slick, currentSlide){
					  		console.log(currentSlide.currentSlide);
					  		if($(this).find('div[data-id="'+currentSlide.currentSlide+'"]').find('iframe').length > 0) {
						  		var actualiframe = $(this).find('div[data-id="'+currentSlide.currentSlide+'"]').find('iframe');

						  		if(!$(actualiframe).attr('src')){
						  			$(actualiframe).attr('src', $(actualiframe).attr('data-src'));
						  		}
						  	} else {
						  		$(this).find('iframe').attr('src', '');
						  	}
						  	//console.log($(this).closest('.cell.large-5.reorder-2'));
						  	if(!$('body').hasClass('page-template-page-accessories') && !$('body').hasClass('page-template-page-toys')){
					    		$(this).closest('.cell.large-5.reorder-2').find('.little-slider').slick('slickGoTo',currentSlide.currentSlide);
					    	} else {
					    		$(this).closest('.cell.image-div-wrap-block.reorder-2').find('.little-slider').slick('slickGoTo',currentSlide.currentSlide);
					    	}
						});

						$('.wooco-wrap').each(function() {
				      wooco_init_selector();
				      wooco_init($(this), 'load');
				    });

				    $(".ajax-loadd").hide();
					}
				}
			});
		}
		
});